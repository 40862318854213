
.transform(@transform){
	-ms-transform: @transform;
	-webkit-transform: @transform;
	-moz-transform: @transform;
	-o-transform: @transform;
	transform: @transform;
}
  
.translateX(@distance){
	.transform(translateX(@direction-mul*@distance));
}

.translateZ(@distance){
	.transform(translateZ(@distance));
}

.transform-origin(@origin){
	
}

.transition(@transition){
	-webkit-transition: @transition;
    -moz-transition: @transition;
    -o-transition: @transition;
    transition: @transition;
}


 

//Convenience functions for ltr/rtl support

.padding-left(@padding){
	padding-@{left}:@padding;	 
}
.padding-right(@padding){
	padding-@{right}:@padding;	
}
.border-left(@border){
	border-@{left}:@border;
}
.border-right(@border){
	border-@{right}:@border;
}
.border-left-width(@width){
	border-@{left}-width:@width;
}
.border-right-width(@width){
	border-@{right}-width:@width;
}
.border-left-color(@color){
	border-@{left}-color:@color;
}
.border-right-color(@color){
	border-@{right}-color:@color;
}
.margin-left(@margin){
	margin-@{left}:@margin;	
}
.margin-right(@margin){
	margin-@{right}:@margin;	
}
.left(@distance){
	@{left}:@distance;	
}
.right(@distance){
	@{right}:@distance;	
}
