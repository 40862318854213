
@screen-sm-min: 768px; // >= this for tablets (2 column)

@screen-md-min: 1024px; // >= this for desktop (2/3 column) - would be (1024px - 32px for scrollbar & frame)


@category-image-height:50px;

@img-border-color: rgb(200,200,200);


//Configuration options
 
//@deal-border-color:orange;
//@deal-border-color:rgba(255, 165, 0, 0.8);
//:rgba(255, 193, 80, 0.8);
@deal-border-color:rgb(255, 205, 90);

@deal-title-color:#ff9200;

@deal-header-background-color:#fff8ec;

@news-header-background-color:rgb(245,245,255);

//@deal-background-color:rgba(255,165,0,0.03);
@deal-background-color:rgba(255, 165, 0, 0.02);

//@header-background-color: rgb(115,199,235); //nice blue

@header-background-color:  rgb(33,178,171); //swamp green (from icon)

//@header-background-color: rgb(103, 184, 193); //swamp green

@deal-text-color:orange;


@screen-tiny-max: 320px; // for iphone5 etc.

@screen-sm-min: 768px; // >= this for tablets (2 column)

@screen-md-min: 1024px; // >= this for desktop (2/3 column) - would be (1024px - 32px for scrollbar & frame)


@screen-lg-min: 1200px; // not used
@screen-xs-max: (@screen-sm-min - 1px);

@font-size-base: 15px;
@line-spacing: 5px;
@lines: 3;

@font-size-body: @font-size-base;
@font-size-title: @font-size-base + 1;
@font-size-small: @font-size-base - 1;

@kosher-color: rgb(100,190,100);


@tab-change-distance:30px;
@tab-change-time:333ms;

//@recommendation-bubble-border-color:#9be29b;	
//@recommendation-bubble-background-color:#f6fff6;

//@recommendation-bubble-border-color:#cee2da;
//@recommendation-bubble-background-color:white;

@recommendation-bubble-border-color:#d3efd3;
@recommendation-bubble-background-color:rgb(250,255,250);

@review-border-color:#c0efb9;
//@review-background-color:#fbfffb;
@review-background-color:#fff;


@light-highlight-color:#fffff9;


@font-face {
	font-family: 'combined';
	src: url('https://modiinapp.com/font/combined.woff');
	src: url('https://modiinapp.com/font/combined.woff') format('woff'),
         url('https://modiinapp.com/font/combined.ttf') format('truetype'),
         url('https://modiinapp.com/font/combined.svg#combined') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'combined';
	src: url('https://modiinapp.com/font/combined-bold.woff');
	src: url('https://modiinapp.com/font/combined-bold.woff') format('woff'),
         url('https://modiinapp.com/font/combined-bold.ttf') format('truetype'),
         url('https://modiinapp.com/font/combined-bold.svg#combined') format('svg');
	font-weight: bold;
	font-style: normal;
}



//Use box sizing everywhere

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  //-webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}


//Basic element styling

body{
	direction: @direction;
	font-family: combined, sans-serif;
	margin:0px;
	overflow-x:hidden;

}


a{
	text-decoration:none;
}

a {
   outline: 0;
}

html,body{
	font-size: 16px;
}

input {
    border-radius: 0;
    -webkit-appearance: none;
}

input[type='text'],
input[type='search'],
input[type='number'],
textarea {
	font-size: 16px;
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

input[type='checkbox'] {
    -webkit-appearance: checkbox;
}

input[type='radio'] {
    -webkit-appearance: radio;
}


.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


button:focus{
    outline: 0;
}





.clearfix(){
	&:after{
		content: "";
		display: table;
		clear: both;	
	}
}


.gradient-vertical (@startColor, @endColor) 
{
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);	
}


.gradient-horizontal (@startColor, @endColor) 
{
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(left, @startColor, @endColor);
	background: -moz-linear-gradient(left, @startColor, @endColor);
	background: -ms-linear-gradient(left, @startColor, @endColor);
	background: -o-linear-gradient(left, @startColor, @endColor);	
}


.symbol-font{
	font-family: 'combined' !important;
    speak: none;
    font-style: normal;
	font-weight: normal;
    font-variant: normal;
    font-transform: normal;
    //line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


//Lazy loading

.img{

	overflow:hidden;
	position:relative;
	background-color:#EFEFEF;

	img{
		position:absolute;
		.left(0);
		top:0;
	    width: 100%; 
		height: 100%;
		.transition(333ms opacity ease-in-out);
		opacity:0;
	    //background-size: 100%;
	    background-size: cover;
	    background-repeat: no-repeat;
	}

	img.loaded{
		opacity:1;
	}
}


//Standard category rendering

.category{

	position:relative;
	cursor:pointer;

	&:hover{
		.title{
			border-bottom-color:rgb(180,180,180);
		}
	}

	.main{

		position: relative;
		.padding-left(@category-image-height+20);
		.padding-right(10px);
		display: block;
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: @font-size-title;
		font-size:15px;
		text-decoration:none;
		color:black;
		vertical-align: middle;
		line-height: @category-image-height+2;
		font-weight: normal;
	}

	.category-content
	{
		display:inline-block;
		line-height:20px;
		vertical-align:middle;
	}

	.deal-count{

		position: absolute;
		.right(10px);
		top: 50%;
		font-size: 15px;
		margin-top: -16px;
		text-align: @right;
		text-decoration: none;
		line-height: 32px;
		@thiscolor:rgb(255,210,0);
		color:@thiscolor;
		font-weight:normal;
	}

	.img{
		position:absolute;
		.left(10px);
		top:50%;
		margin-top:-(1+@category-image-height/2);
		width:@category-image-height+2;
		height:@category-image-height+2;
		border:1px solid @img-border-color;
	}

	.title{
		line-height: 22px;
		display: inline;
		vertical-align: middle;
		font-size:15px;
		//text-decoration: underline;

	    font-weight: bold;
	    font-size: 16px;
	    color: rgba(0,0,0,0.6);

	    color: rgba(0,0,0,0.7);

        color: rgba(0,0,0,.6);

        font-weight:normal;
        color:rgba(0,0,0,0.8);
		border-bottom:1px solid transparent;

	}
}



.homepage-message, .category-message
{
	margin: 10px;
	border: 1px dashed #76bdba;
    padding: 10px;
    line-height: 24px;
    font-size: 15px;
    color: rgb(50,50,50);
    margin-top: 15px;
    border: 2px dashed #a6dcda;

    a
    {
		color: #549cb9;
	    border-bottom: 1px solid #70b2cc;
	    line-height: 24px;
	    display: inline-block;
    }
}

.category-message
{
	color:#868686;
}


.search-extra{
	display:none;
}

.search-list{

	.more
	{
		display:block;
		padding:10px;
		line-height:30px;

		a
		{
			color: #006a7e;
			border-bottom: 1px solid #007f98;
		}

		&:before
		{
			content: "\e81c";
			color: #808080;
			.margin-right(5px);
			float:@left;
		}
	}

	.search-extra-prompt
	{
		display: inline-block;
	    margin: 0px 10px 30px;
	    color: #585858;
	    cursor: pointer;
	    margin-top: 10px;
	    display: block;
	    line-height: 24px;

		span{
		    line-height: 25px;
		    border: 1px solid #d8d8d8;
		    padding: 5px 15px;
		    border-radius: 14px;
		    display: inline-block;

		    /*
			&:before
			{
				content: "\e81c";
				color: #808080;
				.margin-right(5px);
				float:@left;
			}*/		
		}

	}
}



.page-item{
	//.noSelect;

	//border-top: 1px solid rgb(240,240,240);
	border-top: 1px solid rgb(230,230,230);
    max-width: 520px;

	position:relative;
	.padding-right(8px);
	.padding-left(88px);
	display:block;
	padding-top:10px;
	padding-bottom:10px;
	text-decoration:none;
	color:black;
	font-size: @font-size-body;
	line-height: @font-size-body + @line-spacing + 1px;
	z-index:0;

	.drag-handle{
		&:before{
		    height: 40px;
		    width: 50px;
		    text-align: center;
		    line-height: 40px;
		    font-size: 24px;
		    content: '\e82C';
		    display: none;
		    color: #bbbbbb;
		    cursor: move;
		    background-color: rgba(255, 255, 255, 0.7);
		    float: @right;
		    .margin-right(-10px);
		    margin-top: -10px;
		}		
	}

	.delete-link{
		&:before{
		    height: 40px;
	        width: 30px;
		    top: 10px;			
		    text-align: @right;
		    line-height: 37px;
		    font-size: 24px;
		    .right(10px);
		    content:'\e849';
		    display:none;
		    color: rgb(255,128,128);
	        background-color: rgba(255,255,255,0.7);
	        /*
			opacity:0;
			transition:all 300ms ease-in-out;
			transform:translateX(20px);
			*/
			float:@right;
		    margin-top: -10px;
		}

		&:hover:before{
			color:rgb(255,80,80);
		}
		/*
		font-weight: normal;
		color: #ff8080;
    	border-bottom: 1px solid #ff8080;
	    opacity: 0.7;
	    display:none;
		opacity:0;
		transition:all 300ms ease-in-out;
		transform:translateX(-20px);
		line-height: 19px;
		*/
	}


	


	>a{
		color:black;
	}

	&.premium
	{
	    background: @light-highlight-color;
	    border-top: 1px solid #f3e255;
	    border-bottom: 1px solid #f3e255;
	    //margin-bottom: -1px;
	    //z-index: 1;

	    //border-color: #d9d9d9;

    	/*
		background-color: #ffffee;
		border-left:1px solid rgb(240,240,240);
		border-right:1px solid rgb(240,240,240);
		border-top:1px solid rgb(240,240,240);

		//background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAEklEQVQYV2NkgALG+vp6KRAbAAsaAZo0qwPjAAAAAElFTkSuQmCC);
		background-color: @light-highlight-color;
		//background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQYV2P8///xfwYkwIgh8OvXL1QV6AIAb28Pn61Ybo4AAAAASUVORK5CYII=);
		*/
	}

	.cover
	{
	    position: absolute;
	    .left(0px);
	    width:88px;
	    top: 0px;
	    bottom: 0px;
	    background-size: 100%;
	    background-size: cover;
	    background-position: center;

	    &:after{
	    	content:'';
	    	.left(0px);
	    	.right(0px);
	    	top:0px;
	    	bottom:0px;
    	    position: absolute;
	    	.gradient-horizontal(rgba(255,255,255,0.6), rgba(255,255,255,1));
	    }
    }

	.title{
		font-weight: bold;
		font-size: @font-size-title;
		line-height: @font-size-title + @line-spacing;

		//font-weight:normal;
		//font-size:18px;
		//margin-bottom:3px;

	    border-bottom: 1px solid #005090;
	    display: inline;
	    padding-bottom: 0px;
	    border-bottom-color: rgba(255,255,255,0);
	    //transition:all 300ms ease-in-out;

		.margin-right(5px);

   	    color: #005090;
   	    color:#006cc2;
   	    color:#4486ba;
	}

	.title-extra{
		//color:#888888;
	}

	&:hover{
		//background-color:rgb(245,245,245);
		.title{
			border-bottom-color: #cbcbd7;
			border-bottom-color: lighten(#4486ba, 20%);
			//border-bottom-color: spin(lighten(#005090, 10%), 160);
		}
	}

	.description{
		font-weight:normal;
		max-height: (@font-size-body + @line-spacing) * @lines + 1px;
		overflow:hidden;
		position:relative;
		margin-top:5px;

		display: -webkit-box;
		-webkit-line-clamp: @lines;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical; 
	}
	
	.gallery{
		margin-top:10px;
		.margin-right(-5px);
		white-space:nowrap;
		height: 50px;

		.img{
			width:50px;
			height:50px;
			border: 1px solid #d0d0d0;
		    display: inline-block;
		 	.margin-right(8px);
		 	float:@left;

		 	&:last-child{
		 	    margin-right: 0px;
		 	}
		}

		.clearfix();
	}

	.kosher{
		background-color: @kosher-color;
		color: white;
		padding: 0px 6px;
		border-radius: 4px;
		.margin-left(8px);
		letter-spacing: 1px;
	}

	.likes{
		//color:black;
	}
	.likes:after{
		.symbol-font;
		-webkit-font-smoothing: antialiased;
		content: "\e834";
		.margin-left(4px);
		float:@right;
	}

	/*
	.recommendations-count:after{
		.symbol-font;
		-webkit-font-smoothing: antialiased;
		content: "\e820";
		.margin-left(4px);
		float:@right;
	}*/

	.recommendations-count{
		float:@right;	
		//line-height: 18px;
		font-size: 15px;
		vertical-align: bottom;
		.margin-left(16px);
	    outline: 2px solid #8fe382;
	    padding: 0px 8px;
	    position:relative;

	    &:after, &:before {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-right-color: @review-background-color;
			border-width: 6px;
			margin-top: -6px;
		}
		&:before {
			border-right-color: #8fe382;
			border-width: 9px;
			margin-top: -9px;
		}

	}

	.kosher, .likes{
		float:@right;	
		//line-height: 18px;
		font-size: 15px;
		vertical-align: bottom;
		.margin-left(8px);
	}
	
	.date{
		//font-weight:bold;
		float: @right;
	    .margin-left(10px);
	    //font-weight: bold;
	    //color: rgba(0,0,0,0.7);
	}

	.location{
		//margin-top:5px;
		//margin-bottom:5px;
		color:rgb(110,110,110);
		font-size:@font-size-small;
		font-size:15px;
		margin-top:5px;
	}

	.deal-title{
		margin-top: 7px;
		color:orange;
		font-size:15px;
		font-weight:bold;
		display:none;

	}

	.deal-tag{
		position: absolute;
		line-height: 34px;
		background-color: #ffa500;
		color: white;
		width: 50px;
		text-align: center;
		top: -2px;
		bottom: -2px;
		.left(-2px);
		display:none;
	}

	.deal{
		
		.clearfix();

	    position: relative;
	    //padding: 7px 0px;
	    padding: 5px 0px;
	    //.padding-left(20px);
	    .padding-right(5px);
	    //.margin-left(15px);
	    .margin-left(0px);
	    .padding-left(42px);
	    margin-top:10px;
	    margin-bottom:10px;
		border: 2px dashed @deal-border-color;
		color:rgb(100, 80, 50);
		//background-color:@deal-background-color;
		max-width:300px;

    	background-color: rgba(255, 205, 90, 0.05);
		background-color: #fffcfc;
		background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQYV2P8///xfwYkwIgh8OvXL1QV6AIAb28Pn61Ybo4AAAAASUVORK5CYII=);

		//test
		.padding-left(5px);
		.margin-left(-7px);
		background:transparent;
		background: #fffdf8;
		background-color: @light-highlight-color;

    	//.margin-right(-20px);
    	.margin-right(0px);

		.title{
			//font-weight:bold;
			font-weight:normal;
			color:rgb(100, 80, 50);
			//color:@deal-title-color;
			border-bottom:none;


			color:#ff9200;
			font-weight:bold;
			font-size:15px;

			color:rgba(0,0,0,0.9);
			font-weight:normal;
			color:black;

		    color: black;
		    //font-weight:bold;
		    .margin-left(5px);
		    .margin-right(10px);
		    display:block;

		    //.margin-left(30px);
		}

		/*
	    &::after{
			display: block;
		    content: '\e800';
		    color: rgb(255, 205, 89);
		    position: absolute;
		    top: 50%;
		    margin-top: -16px;
		    font-size: 31px;
		    line-height: 32px;
		    .left(0px);
		    width: 32px;
		    text-align: center;

	        .left(-39px);
		}
		*/

		.img{
			float: @right;
			margin-top: 5px;
			border:1px solid @img-border-color;
			margin-bottom: 5px;

		}	
	}


	&.premium .deal{
		background-color:#ffffff;
	}

	.clearfix();

	.page-logo .img{
		margin-top:0px;
		width:66px;
		height:66px;
		border:1px solid @img-border-color;
 		border:1px solid #e0e0e0;
		float:@left;
		.margin-left(-78px);
	}


	@border-color:#70a0e0;




	.recommendations
	{
	    max-width: 380px;
		//margin-bottom:10px;
		//.margin-left(-80px);
		.margin-left(-0px);
	    margin-top: 15px;

		.recommendation{
			
		}

		.recommendation-text{

			font-style: italic;
		    margin-top: 10px;
		    line-height: 22px;
		    background-color: #fafffa;
		    position: relative;
		    padding: 5px 10px;
		    border: 2px solid #d3efd3;
		    -webkit-border-radius: 10px;
		    border-radius: 10px;
		    margin-left: 15px;
		    font-size: 15px;
		    max-width: 350px;
		    margin-left: 57px;


			&:before {
			    content: "";
			    position: absolute;
			    bottom: -11px;
			    .left(14px);
			    border-width: 11px 11px 0;
			    border-style: solid;
			    //border-color: rgb(180,180,180) transparent;
			    border-color: @recommendation-bubble-border-color transparent;
			    display: block;
			    width: 0;
			}
			&:after {
			    content: "";
			    position: absolute;
			    bottom: -08px;
			    .left(17px);
			    border-width: 8px 8px 0;
			    border-style: solid;
			    border-color: @recommendation-bubble-background-color transparent;
			    display: block;
			    width: 0;
			}
		}

		.recommendation-menu-button{
			float:@right;
			letter-spacing:1px;
			margin:0px 7px;
			margin-top:-6px;
			font-weight:bold;
		}

		.recommendation-bottom{
			.margin-left(40px);			
		    height: 21px;
		}

		.recommendation-name{
			font-weight:bold;
		    color: rgb(100,100,100);

		    max-width: 120px;
		    display: inline-block;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;

			/*
				content:'- ';
			}*/
		}

		.recommendation-date{
			color:rgb(100,100,100);
			font-size:14px;
			.margin-left(3px);
			text-transform:lowercase;
			float: @right;
			.margin-right(5px);
		}

	}

	.recommendations-top
	{
		.margin-left(-80px);
	    margin-bottom: 15px;
	    margin-top: 0px;
	}


	.comment
	{
		
		position: relative;
		padding: 3px 10px;
		border: 2px solid @border-color;
		color: #000000;
		background: #fcfcff;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 14px;
		background:@border-color;
vertical-align: middle;
background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQIW2NkQAXGjEh8YwYGhrMwATAHJAkSgHNAAgBPrgM4NDOmCgAAAABJRU5ErkJggg==);
		&:before{
			content: "";
			position: absolute;
			top: -15px;
			.right(10px);
			border-width: 15px 0px 0px 15px;
			border-style: solid;
			border-color: transparent @border-color;
			display: block;
			width: 0;
		}

		.comment-text
		{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 14px;
			line-height: 16px;
			color:white;
			vertical-align: middle;
			display:block;

			font-family:arial;
			font-style:italic;
		}
		
	}
	.author{
		.padding-left(0px);
		padding-top:0px;
		font-weight: bold;
		font-size: 12px;
		color:darken(@border-color, 10%);
		text-align: right;
		.margin-right(32px);
		margin-top: 10px;
		margin-bottom:-2px;

		line-height:20px;
		font-size:14px;
	}

}